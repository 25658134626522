@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add the lines below */
@layer utilities {
  @variants responsive {
    .masonry-col-3 {
      column-count: 3;
      column-gap: 0.5rem;
    }
    .masonry-col-2 {
      column-count: 2;
      column-gap: 0.5rem;
    }
    .masonry-col-1 {
      column-count: 1;
      column-gap: 0.5rem;
    }
    .break-inside {
      break-inside: avoid;
    }
  }
}

html {
  scroll-behavior: smooth;
}
