.ais-InstantSearch__root {
  display: flex;
  border: 1px solid grey;
  list-style: none;
}

.ais-Pagination-list {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 2em;
}

.ais-Pagination-item {
  display: inline-block;
  list-style: none;
  display: "inline-block";
  text-decoration: "none";
}

a[class^="ais-"] {
  text-decoration: none;
}

.ais-Pagination-list {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.ais-Pagination-item + .ais-Pagination-item {
  margin-left: 0.3rem;
}
.ais-Pagination-link {
  padding: 0.2rem 0.55rem;
  display: block;
  border: 1px solid #c4c8d8;
  border-radius: 5px;
  -webkit-transition: background-color 0.2s ease-out;
  transition: background-color 0.2s ease-out;
}
.ais-Pagination-link:hover,
.ais-Pagination-link:focus {
  background-color: #e3e5ec;
}
.ais-Pagination-item--disabled .ais-Pagination-link {
  opacity: 0.6;
  cursor: not-allowed;
  color: #a5abc4;
}
.ais-Pagination-item--disabled .ais-Pagination-link:hover,
.ais-Pagination-item--disabled .ais-Pagination-link:focus {
  color: #a5abc4;
  background-color: #fff;
}
.ais-Pagination-item--selected .ais-Pagination-link {
  color: #fff;
  background-color: black;
}
.ais-Pagination-item--selected .ais-Pagination-link:hover,
.ais-Pagination-item--selected .ais-Pagination-link:focus {
  color: #fff;
}
